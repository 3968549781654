<template>
  <v-container grid-list-sm>
    <v-layout row wrap justify-center>
      <v-flex xs6>
        <v-card v-if="!loginStatus" color="primary" dark>
          <v-card-text>
            {{ $t("login.loadUser") }}
            <v-progress-linear
              indeterminate
              color="white"
              class
            ></v-progress-linear>
          </v-card-text>
        </v-card>
        <v-card v-else dark color="error">
          <v-card-text>
            <fa-icon icon="exclamation-circle" size="lg"></fa-icon>
            {{ $t(`login.${loginStatus}`) }}
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapMutations } from "vuex";
import LoginService from "../services/LoginService";
import { getBoolFromStr } from "../common/BoolParser";

export default {
  name: "OidcCallback",
  data() {
    return {
      loginStatus: ""
    };
  },
  computed: mapState("oidcStore", ["user", "access_token"]),
  methods: {
    ...mapActions("oidcStore", ["oidcSignInCallback"]),
    ...mapMutations({ setUser: "SET_USER" })
  },
  mounted() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        let u = this.user;
        LoginService.GetUser(u).then(userInfo => {
          if (userInfo.isError) {
            this.loginStatus = userInfo.loginStatus;
            return;
          }
          this.setUser({
            ...userInfo,
            ...{
              fullName: u.full_name,
              isLocalUser: getBoolFromStr(u.isLocalUser)
            }
          });
          this.$router.push(redirectPath);
        });
      })
      .catch(err => {
        console.error(err);
        this.loginStatus = "login_error";
      });
  }
};
</script>
